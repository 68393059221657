'use client';

import { useRef, useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { makeStore, AppStore, setData } from '@next-app/lib/store';
import InitialData from './utils/InitialData';
import headerFooterApiData from './utils/get-header-footer-data';
import deviceType from './utils/get-device-type';

export default function StoreProvider({
  initResp,
  Endeca_user_segments,
  Endeca_date,
  children,
}: Readonly<{
  initResp?: any;
  Endeca_user_segments?: string;
  Endeca_date?: string;
  children: React.ReactNode;
}>) {
  const storeRef = useRef<AppStore>();
  if (!storeRef.current) {
    // Create the store instance the first time this renders
    storeRef.current = makeStore();
  }

  return (
    <Provider store={storeRef.current}>
      <StoreProviderWithInit
        initResp={initResp}
        Endeca_user_segments={Endeca_user_segments}
        Endeca_date={Endeca_date}
      >
        {children}
      </StoreProviderWithInit>
    </Provider>
  );
}

export function StoreProviderWithInit({
  initResp,
  children,
  Endeca_user_segments,
  Endeca_date,
}: Readonly<{
  initResp?: any;
  Endeca_user_segments?: string;
  Endeca_date?: string;
  children: React.ReactNode;
}>) {
  const dispatch = useDispatch();

  useEffect(() => {
    const getGSACookie = () => {
      const cookies = document.cookie.split('; ');
      for (const cookie of cookies) {
        const [key, val] = cookie.split('=');
        if (key == 'isGSAUser') {
          return decodeURIComponent(val);
        }
      }
      return 'false';
    };
    const isGSA = getGSACookie();
    InitialData.sessionInfoGetJson({
      endecaUserSegments: Endeca_user_segments,
      endecaDate: Endeca_date,
      ...(!Endeca_user_segments &&
        !Endeca_date &&
        window &&
        window.location.search && {
          searchParams: window.location.search,
        }),
      isGSA,
    }).then(async (sessionInfoData) => {
      if (
        !sessionInfoData ||
        !sessionInfoData.sessionInfo ||
        !sessionInfoData.sessionInfo.sessionInfo
      ) {
        return;
      }
      const { profile, userSegmentsMap } =
        sessionInfoData.sessionInfo.sessionInfo;
      const storeId = profile?.selectedStore?.id;
      const segData = userSegmentsMap?.userSegmentCode;
      const isGSAUser = profile?.gsaUser || false;
      const headerFooter = await headerFooterApiData(
        storeId,
        segData,
        isGSAUser,
      );
      dispatch(
        setData({
          ...sessionInfoData,
          init: initResp,
          headerFooter,
          deviceType: deviceType(),
        }),
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  return children;
}
