'use client';

import { useEffect } from 'react';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    s_c_il: any;
  }
}

const ActivityMapLinkTracking = () => {
  useEffect(() => {
    let activityMapLinkTracking = {};
    const initializeActivityMapLinkTracking = () => {
      if (typeof window.s_c_il !== 'undefined' && window.s_c_il[1]) {
        const activityMap =
          window.s_c_il[1].ActivityMap || window.s_c_il[0].ActivityMap;
        if (activityMap) {
          activityMap.regionIDAttribute = 's_regionid';

          const originalActivityMapLinkFn = activityMap.link;

          activityMap.link = (
            clickedElement: {
              tagName: string;
              hasAttribute: Function;
              getAttribute: Function;
              closest: Function;
            },
            linkName: any,
          ) => {
            let objId;

            if (
              clickedElement &&
              (clickedElement.tagName === 'A' ||
                clickedElement.tagName === 'BUTTON') &&
              clickedElement.hasAttribute('s_objectid')
            ) {
              objId = clickedElement.getAttribute('s_objectid');

              const region = clickedElement.closest('[s_regionid]');
              const regionId =
                region?.getAttribute('s_regionid') ||
                window.location.pathname
                  .split('/')
                  .filter((a) => a !== '')
                  .pop() ||
                'HP';
              const page = regionId?.split('-')[0].trim().toLowerCase();

              if (page !== '') {
                activityMapLinkTracking = {
                  regionId: regionId,
                  objectId: objId,
                };
              }

              if (region && region.getAttribute('data-impressions')) {
                activityMapLinkTracking = {
                  ...activityMapLinkTracking,
                  pageType: region.getAttribute('data-impressions'),
                };
              }
            }

            // Fall back to the original implementation if nothing is found
            return objId || originalActivityMapLinkFn(clickedElement, linkName);
          };
        }
      }
    };

    const timer = setTimeout(() => {
      initializeActivityMapLinkTracking();
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return null;
};

export default ActivityMapLinkTracking;
