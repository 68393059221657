'use client';

declare global {
  interface Window {
    OnetrustActiveGroups: string;
    OneTrust: any;
    FS: any;
  }
}

import { init, FullStory } from '@fullstory/browser';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { SessionInformation } from '../interface/SessionInformation';
import { usePathname } from 'next/navigation';
import { isPdpPage } from '@next-app/utils/PDPHelper';
import { makeFetchCall } from '@next-app/utils/fetch-data';
import urlMapping from '@next-app/config/urlMapping';

interface LoginEventProperties {
  email: string | undefined | null | null;
  firstName: string | undefined | null;
  lastName: string | undefined | null;
  isFurnitureTab: boolean | undefined | null;
  isMobileView: boolean | undefined | null;
  loggedIn: string | undefined | null;
  repositoryId: string | undefined | null;
  sid: string | undefined | null;
  bldno: string | undefined | null;
  damBldno: string | undefined | null;
}

interface FullStoryScriptProps {
  sessionInfo: SessionInformation;
  isMobile: boolean;
}

export default function FullStoryScript(props: FullStoryScriptProps) {
  const { sessionInfo, isMobile } = props;
  const pathname = usePathname();

  const { init: initURLResp } = useSelector((state: any) => state.init);
  const {
    initResponse: {
      fullStoryInfo: {
        fsHost = '',
        fsProxyHost = '',
        fsScriptURL = '',
        fullStoryOrgId = '',
      } = {},
    } = {},
  } = initURLResp || {};
  const internalAPI = initURLResp?.initResponse?.internalAPI;
  const { profile, featureToggles, sid, bldno, damBldno } = sessionInfo;

  useEffect(() => {
    init({
      orgId: fullStoryOrgId,
      host: fsProxyHost,
      appHost: fsHost,
      script: fsScriptURL,
    });

    const properties = {
      email: profile?.email,
      lastName: profile?.lastName,
      firstName: profile?.firstName,
      loggedIn: profile?.loginStatus,
      repositoryId: profile?.id,
      isFurnitureTab: featureToggles?.furnitureTabEnabled,
      isMobileView: isMobile,
      sid: sid,
      bldno: bldno,
      damBldno: damBldno,
    };

    setTimeout(() => {
      if (
        typeof window.OneTrust !== 'undefined' &&
        typeof window.OnetrustActiveGroups !== 'undefined' &&
        typeof window.FS !== 'undefined'
      ) {
        checkAndLoadOneTrustFunc(window.OneTrust, window.FS, internalAPI);
      }
    }, 1000);

    fullStoryLoginEvent(properties);

    if (typeof window !== 'undefined') {
      if (isPdpPage(pathname)) {
        fsSetProperties({ origin: 'ReactPDP' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export const fsDeleteSession = async (
  fsUserId: string,
  internalAPI: string,
) => {
  const reqOpt = {
    endpoint: `${internalAPI}${urlMapping.fsDeleteSession}`,
    options: {
      method: 'POST',
    },
    body: { fsUserId: fsUserId },
  };
  console.log(`FS session delete request for userId ${fsUserId}`);
  const res = await makeFetchCall(reqOpt);
  if (res.status === 'ok' || res.status === 'success') {
    console.log(
      `FS session deleted for the user (userId "${fsUserId}"), ${res.status}-${res.message}`,
    );
  } else {
    console.log(
      `Unable to Delete FS session with userId "${fsUserId}", ${res.status}-${res.message}`,
    );
  }
};

export const checkRecordingConsent = (
  OnetrustActiveGroups: any,
  FS: any,
  internalAPI: string,
) => {
  if (OnetrustActiveGroups.indexOf('L0001') > -1) {
    const fsStarted = window.sessionStorage.getItem('fsRestart');
    if (FS && !fsStarted) {
      FS.restart();
      window.sessionStorage.setItem('fsRestart', 'started');
      window.sessionStorage.removeItem('fsShutdown');
    }
  } else {
    const fsShutdown = window.sessionStorage.getItem('fsShutdown');
    if (FS && typeof FS.getCurrentSessionURL === 'function' && !fsShutdown) {
      const currentSessionURL = FS.getCurrentSessionURL();
      const fsUserId = currentSessionURL
        ? decodeURIComponent(currentSessionURL)
            ?.split('/')
            ?.pop()
            ?.split(':')
            ?.shift()
        : '';
      FS.shutdown();
      window.sessionStorage.setItem('fsShutdown', 'shutdown');
      window.sessionStorage.removeItem('fsRestart');
      //invoke the FS Delete API
      if (fsUserId) {
        fsDeleteSession(fsUserId, internalAPI);
      }
    }
  }
};

export const checkAndLoadOneTrustFunc = (
  OneTrust: any,
  FS: any,
  internalAPI: string,
) => {
  if (
    OneTrust?.GetDomainData()?.['Groups']?.find(
      (obj: any) => obj['OptanonGroupId'] === 'L0001',
    )
  ) {
    setTimeout(() => {
      const OnetrustActiveGroups = window.OnetrustActiveGroups || '';
      checkRecordingConsent(OnetrustActiveGroups, FS, internalAPI);
    }, 2000);
    OneTrust.OnConsentChanged(() => {
      setTimeout(() => {
        const OnetrustActiveGroups = window.OnetrustActiveGroups || '';
        checkRecordingConsent(OnetrustActiveGroups, FS, internalAPI);
      }, 2000);
    });
  }
};

export function fullStoryLoginEvent(properties: LoginEventProperties) {
  const {
    email,
    loggedIn,
    repositoryId,
    isFurnitureTab,
    isMobileView,
    sid,
    bldno,
    damBldno,
    firstName,
    lastName,
  } = properties;

  let profileData = {
    email: email,
    firstname: firstName,
    lastname: lastName,
    serverid: sid,
    buildNo: bldno,
    damBuildNo: damBldno,
    furnitureTab: isFurnitureTab,
    isMobile: isMobileView,
    origin: 'React',
  };

  if (loggedIn === 'loggedIn' && repositoryId) {
    FullStory('setIdentity', {
      uid: repositoryId,
      properties: profileData,
    });
  } else {
    FullStory('setProperties', {
      type: 'user',
      properties: profileData,
    });
  }
  return;
}

export function useFullStoryErrorEvent(errorMsg: string) {
  FullStory('trackEvent', {
    name: 'LL_Form_Error',
    properties: { form_error: errorMsg, origin: 'React' },
  });
}

export const fsSetProperties = (properties: { [key: string]: any }) => {
  FullStory('setProperties', {
    type: 'user',
    properties: properties,
  });
  return;
};
