import { makeFetchCall } from '@next-app/utils/fetch-data';
import urlMapping from '@next-app/config/urlMapping';

const headerFooterApiData = async (
  storeId: string,
  segData: string,
  isGSAUser: boolean,
) => {
  const segNum = segData.replace(/\D+/g, '');
  let headerFooterUrl = urlMapping.headerFooterUrl;
  if (storeId) {
    headerFooterUrl = `${headerFooterUrl}/loc-${storeId}`;
  }
  if (isGSAUser) {
    headerFooterUrl = `${headerFooterUrl}/gsa`;
  }
  if (segNum) {
    headerFooterUrl = `${headerFooterUrl}/seg-${segNum}`;
  }
  const headerFooter = await makeFetchCall({
    endpoint: headerFooterUrl,
  });
  return headerFooter;
};

export default headerFooterApiData;
