'use client';

import Link from '@next-app/components/elements/Link/Link';
import { useSelector } from 'react-redux';
import classes from './header.module.scss';
import Conditional from '@next-app/components/Conditional';

const HeaderTabs = () => {
  const { sessionInfo } = useSelector((state: any) => state.init);
  if (!Object.keys(sessionInfo).length) {
    return (
      <div className={`${classes['headerTab-wrap']} order-md-first order-last`}>
        <div id="website-tabs" className={`${classes.webTab}`}>
          <Link
            href="/"
            className={`${classes['lakeshore-tab']} ${classes.active}`}
          >
            Lakeshore
          </Link>
        </div>
      </div>
    );
  }

  const { profile } = sessionInfo.sessionInfo;
  const { gsaUser } = profile;

  return (
    <div className={`${classes['headerTab-wrap']} order-md-first order-last`}>
      <Conditional if={!gsaUser}>
        <div id="website-tabs" className={`${classes.webTab}`}>
          <Link
            href="/"
            className={`${classes['lakeshore-tab']} ${classes.active}`}
          >
            Lakeshore
          </Link>

          <Link
            href="/furniture"
            className={`${classes['lakeshore-tab']} ${classes['furniture-tab']}`}
          >
            Lakeshore Furniture
          </Link>
        </div>
      </Conditional>
    </div>
  );
};

export default HeaderTabs;
