'use client';

import urlMapping from '@next-app/config/urlMapping';
import { makeFetchCall } from '@next-app/utils/fetch-data';
import { useEffect } from 'react';

interface EventData {
  event: string;
  detail: any;
  Error: any;
}

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    sendDTMEvent: any;
  }
}

interface EventDispatcherProps {
  dtmLoggingEnabled: boolean;
  apiVersion: string;
  lowerEnv: boolean;
  furnitureTabEnabled: boolean;
  isFurnitureTab: boolean;
}

const EventDispatcher = ({
  dtmLoggingEnabled,
  apiVersion,
  lowerEnv,
  furnitureTabEnabled,
  isFurnitureTab,
}: EventDispatcherProps) => {
  useEffect(() => {
    function writeDTMLog(msg: string, data: any) {
      console.log(msg, data);
    }

    function processDTM(args: EventData) {
      let count = 0;

      function retry(data: EventData) {
        try {
          if (data.event) {
            if (furnitureTabEnabled && data.detail) {
              data.detail['isFurniture'] = isFurnitureTab;
            }
            if (data.detail) {
              data.detail['llmAPIVersion'] = apiVersion;
            }
            if (lowerEnv) {
              writeDTMLog('Event Data:', data);
            }
            document.body.dispatchEvent(
              new CustomEvent(data.event, { detail: data.detail }),
            );
            if (dtmLoggingEnabled) {
              makeFetchCall({
                endpoint: `${apiVersion}${urlMapping.AnalyticsDTM}`,
                options: { method: 'POST' },
                body: {
                  eventName: data.event,
                  eventData: data.detail,
                },
              })
                .then((data) => {
                  writeDTMLog('Event API Response:', data);
                })
                .catch((error) => {
                  writeDTMLog('Event API Error:', error);
                });
            }
          } else {
            throw new Error('Invalid Data');
          }
        } catch (err: any) {
          if (count < 3) {
            count++;
            setTimeout(() => {
              retry(data);
            }, 500 * count);
          } else {
            if (data) {
              data['Error'] = err.message;
            }
            writeDTMLog('Error in retry:', data);
          }
        }
      }
      retry(args);
    }

    window.sendDTMEvent = function (data: EventData) {
      processDTM(data);
    };
  }, [
    apiVersion,
    furnitureTabEnabled,
    lowerEnv,
    dtmLoggingEnabled,
    isFurnitureTab,
  ]);

  return null;
};

export default EventDispatcher;
