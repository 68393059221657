import { makeFetchCall } from './fetch-data';
import urlMapping from '@next-app/config/urlMapping';

const InitialData = () => {
  return {
    getJson: async ({
      Endeca_user_segments,
      Endeca_date,
    }: { Endeca_user_segments?: string; Endeca_date?: string } = {}) => {
      const queryParams = new URLSearchParams();

      // Add optional parameters if they are provided
      if (Endeca_user_segments)
        queryParams.append('endeca_user_segments', Endeca_user_segments);
      if (Endeca_date) queryParams.append('Endeca_date', Endeca_date);

      const endpoint = `${urlMapping.headerFooterUrl}?${queryParams.toString()}`;

      const headerFooter = await makeFetchCall({
        endpoint: endpoint,
        tags: ['headerFooter'],
      });
      return {
        headerFooter,
      };
    },

    sessionInfoGetJson: async ({
      endecaUserSegments,
      endecaDate,
      searchParams,
      isGSA = '',
    }: {
      endecaUserSegments?: string;
      endecaDate?: string;
      searchParams?: string;
      isGSA?: string;
    } = {}) => {
      const body: Record<string, string> = {};
      if (endecaUserSegments) body['Endeca_user_segments'] = endecaUserSegments;
      if (endecaDate) body['Endeca_date'] = endecaDate;
      const sessionInfo = await makeFetchCall({
        endpoint: `${urlMapping.sessionInformationUrl}${
          isGSA !== 'true' && searchParams ? `${searchParams}` : ''
        }`,
        options: {
          method: 'POST',
          body: JSON.stringify(body),
          headers: { 'Content-Type': 'application/json', isReact: 'true' },
        },
      });
      return {
        sessionInfo,
      };
    },
  };
};

export default InitialData();
