'use client';
/* eslint-disable @next/next/no-css-tags */
import Script from 'next/script';

const SmartBanner = () => {
  return (
    <>
      <link rel="stylesheet" href="/react/css/smartbanner.min.css"></link>
      <Script
        src={'/react/scripts/smartbanner.min.js'}
        type="text/javascript"
        strategy="beforeInteractive"
      />
    </>
  );
};

const SmartBannerMeta = () => {
  return (
    <>
      <meta name="smartbanner:title" content="Lakeshore Learning Materials" />
      <meta name="smartbanner:author" content="Lakeshore Learning Materials" />
      <meta name="smartbanner:price" content="FREE" />
      <meta
        name="smartbanner:price-suffix-apple"
        content=" - On the App Store"
      />
      <meta
        name="smartbanner:price-suffix-google"
        content=" - In Google Play"
      />
      <meta
        name="smartbanner:icon-apple"
        content="https://is5-ssl.mzstatic.com/image/thumb/Purple112/v4/6e/bd/74/6ebd74e0-4d21-1796-e168-5f39c1e1981b/AppIcon-0-0-1x_U007emarketing-0-0-0-5-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/230x0w.webp"
      />
      <meta
        name="smartbanner:icon-google"
        content="http://lh3.ggpht.com/f4oX61ljZ6x8aYDELZOgxlvdUEu73-wSQ4fy5bx6fCRISnZP8T353wdaM43RO_DbGg=w300"
      />
      <meta name="smartbanner:button" content="View" />
      <meta
        name="smartbanner:button-url-apple"
        content="https://apps.apple.com/app/apple-store/1589186464"
      />
      <meta
        name="smartbanner:button-url-google"
        content="https://play.google.com/store/apps/details?id=com.lakeshorelearning.mobileapp&referrer=utm_source=lakeshore&utm_medium=web&utm_campaign=b2c.smartbanner.mobile"
      />
      <meta name="smartbanner:enabled-platforms" content="android,ios" />
      <meta name="smartbanner:close-label" content="Close" />
      {/* <meta name="smartbanner:exclude-user-agent-regex" content="^.*(Version).*Safari" /> */}
    </>
  );
};

export { SmartBanner, SmartBannerMeta };
