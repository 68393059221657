'use client';

import Conditional from '@next-app/components/Conditional';
import FullStoryScript from '@next-app/script/FullStoryScript';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import getDevice from '@next-app/components/HOC/GetDevice';
import { WithDeviceProps } from '@next-app/components/HOC/GetDevice';

interface FullStoryProps extends WithDeviceProps {
  isMobile: boolean;
}
const FullStoryEnabled = ({ isMobile }: FullStoryProps) => {
  const { init, sessionInfo } = useSelector((state: any) => state.init);
  const [fullStoryEnabled, setFullStoryEnabled] = useState(false);
  const [sessionInfoVal] = useState(sessionInfo);

  useEffect(() => {
    const fullStoryTrackingEnabled =
      sessionInfo?.sessionInfo?.featureToggles?.fullStoryTrackingEnabled;
    setFullStoryEnabled(fullStoryTrackingEnabled);
  }, [init, sessionInfo]);

  return (
    <Conditional if={fullStoryEnabled}>
      <FullStoryScript sessionInfo={sessionInfoVal} isMobile={isMobile} />
    </Conditional>
  );
};

export default getDevice(FullStoryEnabled);
