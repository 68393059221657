'use client';

import Conditional from '@next-app/components/Conditional';
import EventDispatcher from '@next-app/lib/EventDispatcher';
import { useSelector } from 'react-redux';

const DtmAnalytics = () => {
  const { init, sessionInfo } = useSelector((state: any) => state.init);

  const internalAPI = init?.initResponse?.internalAPI;
  const lowerEnv = init?.initResponse?.lowerEnv;
  const furnitureTabEnabled =
    sessionInfo?.sessionInfo?.featureToggles?.furnitureTabEnabled;
  const dtmLoggingEnabled =
    sessionInfo?.sessionInfo?.featureToggles?.dtmLoggingEnabled;
  const isFurnitureTab = sessionInfo?.sessionInfo?.furniture;
  let hasData = Object.keys(sessionInfo).length > 0;

  return (
    <Conditional if={hasData}>
      <EventDispatcher
        dtmLoggingEnabled={dtmLoggingEnabled}
        apiVersion={internalAPI}
        lowerEnv={lowerEnv}
        furnitureTabEnabled={furnitureTabEnabled}
        isFurnitureTab={isFurnitureTab}
      />
    </Conditional>
  );
};

export default DtmAnalytics;
