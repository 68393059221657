'use client';

import Conditional from '@next-app/components/Conditional';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export default function AudioEyeEnabled() {
  const { init, sessionInfo } = useSelector((state: any) => state.init);
  const [audioEyeUrl, setAudioEyeUrl] = useState(
    'dbb8172bf776f083f57660f0755ea6ff',
  );

  const [isAudioEyeEnabled, setIsAudioEyeEnabled] = useState(false);

  useEffect(() => {
    const audioEyeSiteHash = init?.initResponse?.audioEyeInfo?.audioEyeSiteHash;
    (window as any)['__AudioEyeSiteHash'] = audioEyeSiteHash;
    const audioEyeURL =
      init?.initResponse?.audioEyeInfo?.audioEyeScriptURL ||
      'https://wsmcdn.audioeye.com/aem.js';
    setAudioEyeUrl(audioEyeURL);

    const isAudioEyeEnabledVal =
      sessionInfo?.sessionInfo?.featureToggles?.audioEyeEnabled;

    setIsAudioEyeEnabled(isAudioEyeEnabledVal);
  }, [init, sessionInfo]);

  return (
    <Conditional if={isAudioEyeEnabled}>
      <Script src={audioEyeUrl} />
    </Conditional>
  );
}
