function deviceType() {
  const userAgent = navigator.userAgent;
  const isTablet = /Tablet|iPad/i.test(userAgent);
  const isIpad =
    /iPad|Macintosh/.test(navigator.userAgent) &&
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 1;
  const isMobile =
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile/i.test(userAgent) &&
    !isTablet;

  if (isTablet || isIpad) {
    return 'tablet';
  } else if (isMobile) {
    return 'mobile';
  } else {
    return 'desktop';
  }
}

export default deviceType;
