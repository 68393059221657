'use client';

import { useEffect } from 'react';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    OneTrust: any;
    OnetrustActiveGroups: string;
  }
}

const UpdateDYConsentScript = () => {
  useEffect(() => {
    if (
      window &&
      window.DY &&
      window.DYO &&
      window.OneTrust &&
      window.OnetrustActiveGroups
    ) {
      setTimeout(() => {
        updateDyActiveConsent();
      }, 3000);
      window.OneTrust.OnConsentChanged(() => {
        updateDyActiveConsent();
      });
    }
  }, []);
  return null;
};

const updateDyActiveConsent = () => {
  const oneTrustActiveGroups = window.OnetrustActiveGroups || '';
  const accepted = oneTrustActiveGroups.indexOf('C0004') !== -1;

  window.DY = window.DY || {};
  window.DY.userActiveConsent = { accepted: accepted };
  if (window.DYO?.ActiveConsent?.updateConsentAcceptedStatus) {
    window.DYO.ActiveConsent.updateConsentAcceptedStatus(accepted);
  }

  if (!window.localStorage.getItem('userActiveConsent')) {
    window.localStorage.setItem('userActiveConsent', accepted.toString());
  }

  if (
    !accepted ||
    window.localStorage.getItem('userActiveConsent') == 'false'
  ) {
    const dyid_server = document.cookie
      .split('; ')
      .find((row) => row.startsWith('_dyid_server='))
      ?.split('=')[1];

    if (dyid_server) {
      localStorage.setItem('dyid_server', dyid_server);
    }

    document.cookie = '_dyid=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
    document.cookie =
      '_dyid_server=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
  }
};

export default UpdateDYConsentScript;
