'use client';

import { isBrowser } from '@next-app/utils/common-utils';
import deviceType from '@next-app/utils/get-device-type';
import React, { useEffect } from 'react';

const AddDeviceTypeOnBody = () => {
  useEffect(() => {
    if (isBrowser()) {
      document.body.classList.add(deviceType());
    }
  }, []);

  return <></>;
};

export default AddDeviceTypeOnBody;
