'use client';

import Link from '@next-app/components/elements/Link/Link';
import Input from '../elements/Input/Input';
import classes from './forgot.module.scss';
import { useState } from 'react';
import { forgotModalNames } from '@next-app/config/elementNameConfig';
import Modal from '../elements/Modal/Modal';
import {
  forgotUserResetCode,
  forgotUserResetCodeValidate,
  resetPasswordB2C,
} from '../../utils/authenticationUser';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setData } from '@next-app/lib/store';
import parse from 'html-react-parser';
import Button from '../elements/Button/Button';
import Conditional from '../Conditional';
import { getUniqueKey } from '@next-app/utils';
import { decodeHTMLEntities } from '@next-app/utils/decodeHTMLEntities';

const ForgotModal = () => {
  const dispatch = useDispatch();

  const initial = useSelector((state: any) => state.init);
  const { sessionInfo, init } = initial;

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [isFirstTrue, setIsFirstTrue] = useState(false);
  const [isSecondTrue, setIsSecondTrue] = useState(false);
  const [isMessage, setIsMessage] = useState('');
  const [password, setPassword] = useState('');
  const [isSectionVisible, setIsSectionVisible] = useState(false);
  const [code, setCode] = useState('');
  const [error, setErrors] = useState({
    emailError: '',
    codeError: '',
    passwordError: '',
  });

  const [validations, setValidations] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
    noSpaces: false,
  });

  const [currentSection, setCurrentSection] = useState(0);

  if (!Object.keys(sessionInfo).length || !init || !init.initResponse) {
    return;
  }

  const handleFocus = () => {
    setIsSectionVisible(true);
  };

  const handlePasswordChange = (e: any) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
  };

  const validatePassword = (password: any) => {
    const validations = {
      length: password.length >= 10,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[~!@#$%^*()\-_=+|;:,.?]/.test(password),
      noSpaces: !/\s/.test(password),
    };
    setValidations(validations);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleValidation = (formExceptions: any) => {
    const isEmailError = formExceptions.find(
      (el: any) => el.propertyPath === 'email',
    );
    const isCodeError = formExceptions.find(
      (el: any) => el.propertyPath === 'code',
    );

    const passwordError = formExceptions.find(
      (el: any) => el.propertyPath === 'password',
    );

    if (isEmailError) {
      setErrors((prev) => ({
        ...prev,
        emailError: isEmailError.localizedMessage,
      }));
    }

    if (isCodeError) {
      setErrors((prev) => ({
        ...prev,
        codeError: isCodeError.localizedMessage,
      }));
    }

    if (passwordError) {
      setErrors((prev) => ({
        ...prev,
        passwordError: passwordError.localizedMessage,
      }));
    }
  };

  const dynSessConfNumber =
    sessionInfo?.sessionInfo?.dynSess?.dynSessConfNumber;

  if (!dynSessConfNumber) {
    return;
  }

  const handleSubmit = async () => {
    if (currentSection === 0) {
      const fortPassGetCode: any = await forgotUserResetCode(
        email,
        dynSessConfNumber,
      );
      if (fortPassGetCode?.formError) {
        // show error invalid email
        const { formExceptions } = fortPassGetCode;
        await handleValidation(formExceptions);
      } else {
        setIsFirstTrue(true);
        // set sate of  success data [isFirstTrue]
        const { message } = fortPassGetCode;
        setIsMessage(decodeHTMLEntities(message));
        setCurrentSection((prevSection) => prevSection + 1);
      }
    }

    if (isFirstTrue && currentSection === 1) {
      const forgetPassVerifyCode: any = await forgotUserResetCodeValidate(
        email,
        code,
        dynSessConfNumber,
      );
      if (forgetPassVerifyCode?.formError) {
        // show error invalid code
        const { formExceptions } = forgetPassVerifyCode;
        handleValidation(formExceptions);
      } else {
        // set sate of  success data [isSecondTrue]
        setIsSecondTrue(true);
        setCurrentSection((prevSection) => prevSection + 1);
      }
    }

    if (isSecondTrue && currentSection === 2) {
      const setPassword: any = await resetPasswordB2C(
        email,
        password,
        password,
        code,
        '',
        dynSessConfNumber,
      );
      if (setPassword?.formError) {
        // enter valid password
        const { formExceptions } = setPassword;
        handleValidation(formExceptions);
      } else {
        // set local storage or state
        setCurrentSection((prevSection) => prevSection + 1);
        dispatch(
          setData({
            ...initial,
            sessionInfo: setPassword,
          }),
        );
      }
    }
  };

  const matchedCriteriaCount =
    Object.values(validations).filter(Boolean).length;

  const getPasswordStrength = () => {
    if (matchedCriteriaCount === 6) {
      return { text: 'Strong', color: 'green', percentage: 100 };
    } else if (matchedCriteriaCount >= 5) {
      return { text: 'Moderate', color: 'orange', percentage: 66 };
    } else if (matchedCriteriaCount >= 2) {
      return { text: 'Poor', color: 'red', percentage: 33 };
    } else {
      return { text: '', color: 'grey', percentage: 0 };
    }
  };

  const passwordStrength = getPasswordStrength();

  const closeSection = () => {
    setCurrentSection(0);
  };

  const isAnyValidationMet = Object.values(validations).some(Boolean);

  return (
    <Modal
      showHeader={true}
      id="forgotModal"
      dialogClass={`modal-dialog ${classes['modalDialogPassword']}`}
      contentClass={`modal-content ${classes['modalContent']}`}
      headingTagName="4"
      footerClass="modal-footer"
      modalClass="fade"
      closeModalOnClick={() => {
        setErrors({
          emailError: '',
          passwordError: '',
          codeError: '',
        });
        closeSection();
      }}
      modalTitle="Forgot Password"
      wrapperClass={`${classes['modalWrapperSection']}`}
      modalBodyId="modalBody"
      titleClass={classes['forgot-modal-title']}
    >
      <div {...{ s_regionid: 'forgotPassModal' }}>
        <div>
          <Conditional if={currentSection === 0}>
            <div
              className={`${classes['forgotModalContent']} ${classes['step1']}`}
            >
              <p className={`${classes['signUpText']}`}>
                {forgotModalNames.enterMailId}
              </p>
              <Input
                automation-id="reset_pass_email"
                datatestid="input-forgot-email"
                type="email"
                labelText="Email"
                customClass={error.emailError ? classes['input-error'] : ''}
                id={`${getUniqueKey(1, 'input-forgot-email')}`}
                onChange={(e) => setEmail(e.target.value)}
              />
              {error?.emailError && (
                <div className={`${classes['error-msg']}`}>
                  {error?.emailError}
                </div>
              )}
            </div>
          </Conditional>
          <Conditional if={currentSection === 1}>
            <div
              className={`${classes['forgotModalContent']} ${classes['step2']}`}
            >
              <p className={`${classes['signUpText']}`}>{parse(isMessage)}</p>
              <Input
                type="text"
                labelText="Enter Code"
                customClass={error.emailError ? classes['input-error'] : ''}
                onChange={(e) => setCode(e.target.value)}
              />
              {error?.codeError && (
                <div className={`${classes['error-msg']}`}>
                  {error?.codeError}
                </div>
              )}
            </div>
          </Conditional>
          <Conditional if={currentSection === 2}>
            <div
              className={`${classes['forgotModalContent']} ${classes['step3']}`}
            >
              <div className={`${classes['inputPassword']}`}>
                <Input
                  data-testid="forget-password-input"
                  type={showPassword ? 'text' : 'password'}
                  labelText="Password"
                  id="forget-password"
                  customClass={error.emailError ? classes['input-error'] : ''}
                  onFocus={handleFocus}
                  onBlur={() => setIsSectionVisible(false)}
                  onChange={handlePasswordChange}
                />
                <i
                  className={
                    showPassword
                      ? `${classes['password-icon']} ${classes['hideIcon']}`
                      : `${classes['password-icon']} ${classes['showIcon']}`
                  }
                  onClick={togglePasswordVisibility}
                  role="button"
                  aria-label="Show Password"
                  data-testid="password-eye"
                ></i>
              </div>
              {error?.passwordError && (
                <div className={`${classes['error-msg']}`}>
                  {error?.passwordError}
                </div>
              )}
              {isSectionVisible && (
                <div className={`${classes['passwordConditionsWrapper']}`}>
                  <ul>
                    <li
                      style={{
                        color: validations.length
                          ? 'green'
                          : isAnyValidationMet
                            ? 'black'
                            : 'grey',
                      }}
                    >
                      <span className="ls-icon icn-x-close-hvy-circle"></span>
                      Minimum 10 characters
                    </li>
                    <li
                      style={{
                        color: validations.uppercase
                          ? 'green'
                          : isAnyValidationMet
                            ? 'black'
                            : 'grey',
                      }}
                    >
                      <span className="ls-icon icn-x-close-hvy-circle"></span>
                      Minimum one uppercase letter
                    </li>
                    <li
                      style={{
                        color: validations.lowercase
                          ? 'green'
                          : isAnyValidationMet
                            ? 'black'
                            : 'grey',
                      }}
                    >
                      <span className="ls-icon icn-x-close-hvy-circle"></span>
                      Minimum one lowercase letter
                    </li>
                    <li
                      style={{
                        color: validations.number
                          ? 'green'
                          : isAnyValidationMet
                            ? 'black'
                            : 'grey',
                      }}
                    >
                      <span className="ls-icon icn-x-close-hvy-circle"></span>
                      Minimum one number
                    </li>
                    <li
                      style={{
                        color: validations.specialChar
                          ? 'green'
                          : isAnyValidationMet
                            ? 'black'
                            : 'grey',
                      }}
                    >
                      <span className="ls-icon icn-x-close-hvy-circle"></span>
                      Minimum one special character: `~!@#$%^*()-_=+|;:,.?
                    </li>
                    <li
                      style={{
                        color: validations.noSpaces
                          ? 'green'
                          : isAnyValidationMet
                            ? 'black'
                            : 'grey',
                      }}
                    >
                      <span className="ls-icon icn-x-close-hvy-circle"></span>
                      No spaces
                    </li>
                  </ul>

                  <div>
                    <h6 style={{ color: passwordStrength.color }}>
                      {' '}
                      Strength: {passwordStrength.text}
                    </h6>
                    <div
                      style={{
                        height: '20px',
                        width: '100%',
                        backgroundColor: 'white',
                        border: '1px solid #d8d8d8',
                        borderRadius: '5px',
                        margin: '10px 0',
                      }}
                    >
                      <div
                        style={{
                          height: '100%',
                          width: `${passwordStrength.percentage}%`,
                          backgroundColor: passwordStrength.color,
                          borderRadius: '5px',
                          textAlign: 'center',
                          lineHeight: '20px',
                          color: 'white',
                        }}
                      >
                        {/* {passwordStrength.percentage}% */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Conditional>
          <Conditional if={currentSection === 3}>
            <div
              className={`${classes['forgotModalContent']} ${classes['step4']}`}
            >
              You have successfully reset your password. Click{' '}
              <Link onClick={closeSection} data-bs-toggle="modal" href="/">
                {' '}
                Continue{' '}
              </Link>{' '}
              to sign in.
            </div>
          </Conditional>
        </div>
        <div>
          <Conditional if={currentSection < 3}>
            <div className="d-flex justify-content-end gap-4">
              <Button
                automation-id="reset_pass_cancel"
                onClick={closeSection}
                variant="ternary"
                data-bs-dismiss="modal"
              >
                {' '}
                Cancel
              </Button>
              <Button
                automation-id="reset_pass_button"
                data-testid="submit-button-forgot"
                onClick={handleSubmit}
              >
                {' '}
                Submit
              </Button>
            </div>
          </Conditional>
        </div>
      </div>
    </Modal>
  );
};

export default ForgotModal;
